<template lang="pug">
  section.trainers
    .trainers__container
      vue-slick-carousel.trainers__slider(v-bind="settingsTrainers")
        .trainers__slide(
          v-for="(trainerItem, trainerIndex) in trainersData"
          :key="trainerIndex"
        )
          .trainers__img
            img(
              :src="trainerItem.image"
              :alt="trainerItem.name"
            )
          .trainers__info
            router-link.trainers__link(to="team") Наша команда
            h3.trainers__title {{ trainerItem.name }}
            .trainers__text
              p.trainers__position {{ trainerItem.position }}
              p(
                v-for="(paragraphItem, paragraphIndex) in trainerItem.paragraphs"
                :key="paragraphIndex"
              ) {{ paragraphItem }}
              p.trainers__quote(v-if="trainerItem.quote") {{ trainerItem.quote }}
            .trainers__footer
              .trainers__table
                table
                  tbody
                    tr
                      td Напрямок:
                      td {{ trainerItem.direction }}
                    tr
                      td Контакти:
                      td.trainers__social
                        a(:href="trainerItem.instagram")
                          img(
                            :src="InstagramSocial"
                            alt="instagram"
                          )
                        a(:href="trainerItem.facebook")
                          img(
                            :src="FacebookSocial"
                            alt="facebook"
                          )
              a.schedule__link.link(href="tel:+380687835335") Запис
</template>

<script>
// constans
import { trainersData } from '@/configs/constants'
// VueSlickCarousel
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// images
import InstagramSocial from '@/assets/images/social/instagram.svg'
import FacebookSocial from '@/assets/images/social/facebook.svg'
export default {
  name: 'TrainersBlock',
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      InstagramSocial,
      FacebookSocial,
      trainersData: trainersData,
      settingsTrainers: {
        arrows: true,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
      }
    }
  }
}
</script>
