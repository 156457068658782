// images
import Kariakin from '@/assets/images/home-page/trainers/kariakin.webp'
import Semenko from '@/assets/images/home-page/trainers/semenko.webp'
import Panasenko from '@/assets/images/home-page/trainers/panasenko.webp'
import Kholod from '@/assets/images/home-page/trainers/kholod.webp'
import Belenkov from '@/assets/images/home-page/trainers/belenkov.webp'
import Balashov from '@/assets/images/home-page/trainers/balashov.webp'

export const trainersData = [
  {
    name: 'Карякін Павло Валентинович',
    image: Kariakin,
    position: 'Керівник',
    paragraphs: [
      'Вік: 39 років',
      'Освіта: магістр фізичного виховання, аспірант.',
      'Педагогічний досвід: 13 років. Тренер-викладач І категорії. Виховав 2 кандидатів в майстри спорту України і 7 “розрядників” зі Спасу, більше 15 чемпіонів України.',
      'Особисті досягнення: отаман команди з боїв “Лава на Лаву”, чемпіон України.',
      'Судя ІІ категорії зі Спасу.'
    ],
    quote: '— Якщо хочеш змінити світ, людино, змінюйся сам, а через себе і весь світ.',
    direction: 'СПАС',
    instagram: 'https://www.instagram.com/pavel_kariakin_zp',
    facebook: 'https://www.facebook.com/pavel.karyakin.7'
  },
  {
    name: 'Семенко Андрій Вікторович',
    image: Semenko,
    position: 'Тренер',
    paragraphs: [
      'Вік: 35 років.',
      'Кандидат в майстри спорту України з боксу',
      'Освіта: магістр з фізичного виховання',
      'Педагогічний досвід: 6 років. Тренер-викладач з боксу ІІ категорії',
      'Особисті досягнення: призер чемпіонатів України, Міжнародних та Всеукраїнських турнірів'
    ],
    quote: '–– Найсерйозніший суперник - це ти сам. Переможеш себе - переможеш будь-кого',
    direction: 'Бокс',
    instagram: 'https://www.instagram.com/vidarzp/',
    facebook: 'https://www.facebook.com/vidarzp'
  },
  {
    name: 'Панасенко Юрій Юрійович',
    image: Panasenko,
    position: 'Тренер',
    paragraphs: [
      'Вік: 35 років',
      'Майстер спорту України',
      'Освіта: магістр з фізичного виховання.',
      'Педагогічний досвід: 13 років безперервного стажу в Вільнянській ДЮСШ.',
      'Тренер І категорї з дзюдо.',
      'Суддя І категорії з дзюдо.',
      'Підготував 4 кандидатів в майстри спорту України та 2 тренерів за час роботи.'
    ],
    quote: '–– Охопити якомога більше дітей для занять спортом та максимально довго зберегти це бажання в них!',
    direction: 'Дзюдо',
    instagram: 'https://www.instagram.com/judo1988judo',
    facebook: 'https://www.facebook.com/profile.php?id=100073361997749'
  },
  {
    name: 'Холод Михайло Сергійович',
    image: Kholod,
    position: 'Тренер',
    paragraphs: [
      'Вік: 23 роки.',
      'Кандитат в майстри спорту України.',
      'Освіта: бакалавр з фізичного виховання.',
      'Педагогічний досвід: 2 роки в Вільнянській ДЮСШ.'
    ],
    quote: '–– Залучати дітей до занять спортом та виховувати спортивні якості!',
    direction: 'Дзюдо',
    instagram: 'https://www.instagram.com/michael____kholod/',
    facebook: 'https://www.facebook.com/profile.php?id=100033744967258'
  },
  {
    name: 'Бєлєнков Володимир Сергійович',
    image: Belenkov,
    position: 'Тренер',
    paragraphs: [
      'Вік: 37 років',
      'Кандидат у майстри спорту зі Спасу та з рукопашного бою.',
      'Педагогічний досвід: 10 років. Тренер І категорії.',
      'Особисті досягнення: Володар кубку Єрмака, Чемпіон Європи зі Спасу, багаторазовий Чемпіон України зі Спасу.',
      'Суддя національної категорії.',
      'Голова суддівської ради Всеукраїнської федерації "Спас".'
    ],
    direction: 'СПАС',
    instagram: 'https://www.instagram.com/vladimir_spas',
    facebook: 'https://www.facebook.com/vovan.belenkov'
  },
  {
    name: 'Балашов Борис Сергійович',
    image: Balashov,
    position: 'Тренер',
    paragraphs: [
      'Вік: 22 роки.',
      'КМС з бойового мистецтва українського рукопашу “Спас”.',
      'Освіта: бакалавр з фізичного виховання.',
      'Досвід: тренер зі Спасу з 01.09.2022.'
    ],
    quote: '–– Неможливе можливо.',
    direction: 'СПАС',
    instagram: 'https://www.instagram.com/balashov_coach',
    facebook: 'https://www.facebook.com/profile.php?id=100009621649013'
  }
]

export const categoriesData = [
  {
    slides: [
      {
        title: 'ВСЕ',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Балашов Борис',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'БОКС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'СПАС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Балашов Борис',
            isBlack: false
          }
        ]
      },
      {
        title: 'ДЗЮДО',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          }
        ]
      }
    ]
  },

  {
    slides: [
      {
        title: 'ВСЕ',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          },
          {
            date: 'Вечірнє тренування',
            time: '18:00-19:30',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          },
          {
            date: 'Вечірнє тренування',
            time: '19:30-21:00',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          }
        ]
      },
      {
        title: 'БОКС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'СПАС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          }
        ]
      },
      {
        title: 'ДЗЮДО',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Вечірнє тренування',
            time: '18:00-19:30',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          },
          {
            date: 'Вечірнє тренування',
            time: '19:30-21:00',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          }
        ]
      }
    ]
  },

  {
    slides: [
      {
        title: 'ВСЕ',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Балашов Борис',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'БОКС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'СПАС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Балашов Борис',
            isBlack: false
          }
        ]
      },
      {
        title: 'ДЗЮДО',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          }
        ]
      }
    ]
  },

  {
    slides: [
      {
        title: 'ВСЕ',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          },
          {
            date: 'Вечірнє тренування',
            time: '18:00-19:30',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          },
          {
            date: 'Вечірнє тренування',
            time: '19:30-21:00',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          }
        ]
      },
      {
        title: 'БОКС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'СПАС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          }
        ]
      },
      {
        title: 'ДЗЮДО',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Вечірнє тренування',
            time: '18:00-19:30',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          },
          {
            date: 'Вечірнє тренування',
            time: '19:30-21:00',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: true
          }
        ]
      }
    ]
  },

  {
    slides: [
      {
        title: 'ВСЕ',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Балашов Борис',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'БОКС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '16:30-18:00',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'СПАС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '15:00-16:30',
            direction: 'Спас',
            trainer: 'Балашов Борис',
            isBlack: false
          }
        ]
      },
      {
        title: 'ДЗЮДО',
        tabs: [
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          }
        ]
      }
    ]
  },

  {
    slides: [
      {
        title: 'ВСЕ',
        tabs: [
          {
            date: 'Денне тренування',
            time: '10:00-11:30',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '10:30-12:00',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '12:00-13:30',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          }
        ]
      },
      {
        title: 'БОКС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '10:00-11:30',
            direction: 'Бокс',
            trainer: 'Семенко Андрій',
            isBlack: false
          }
        ]
      },
      {
        title: 'СПАС',
        tabs: [
          {
            date: 'Денне тренування',
            time: '12:00-13:30',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Спас',
            trainer: 'Бєлєнков Володимир',
            isBlack: false
          }
        ]
      },
      {
        title: 'ДЗЮДО',
        tabs: [
          {
            date: 'Денне тренування',
            time: '10:30-12:00',
            direction: 'Дзюдо',
            trainer: 'Холод Михайло',
            isBlack: false
          },
          {
            date: 'Денне тренування',
            time: '13:30-15:00',
            direction: 'Дзюдо',
            trainer: 'Панасенко Юрій',
            isBlack: false
          }
        ]
      }
    ]
  }
]